import { Flex, Image, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { Chip } from '@ubirider/pick-component-library'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlertCauses, AlertEffects } from '../../consts/alerts'
import { Link } from 'react-router-dom'
import { AffectedRoutesComponent } from './components/AffectedRoutesComponent'
import { AffectedStopsComponent } from './components/AffectedStopsComponent'
import { AffectedTransportsComponent } from './components/AffectedTransportsComponent'

import closeButton from '../../assets/cross.svg'
import infoIcon from '../../assets/alert_blue_full.svg'
import alertIcon from '../../assets/alert_yellow_full.svg'
import dangerIcon from '../../assets/alert_red_full.svg'

export const AlertModal = props => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} autoFocus={false} isCentered>
      <ModalOverlay />
      <ModalContent
        padding='16px'
        border='1px solid #98A2B3'
        borderRadius='16px'
        maxW={{ sm: 'calc(100vw - 32px)', lg: '624px' }}
        box-shadow='0px 0px 20px rgba(0, 0, 0, 0.1)'
        gap='16px'
      >
        <Flex direction='row' justify='space-between' align='flex-start' gap='8px'>
          <Flex gap='16px' maxW={{ sm: '85%', lg: '95%' }} align='flex-start'>
            <Image src={props.alert?.alertLevel === 0 ? infoIcon : props.alert?.alertLevel === 1 ? alertIcon : props.alert?.alertLevel === 2 ? dangerIcon : null} />
            <Text fontSize='16px' fontWeight='700' color='#1D2939'>
              {props.alert?.header}
            </Text>
          </Flex>

          <Flex as='button' onClick={props.onClose}>
            <Image src={closeButton} />
          </Flex>
        </Flex>
        <Flex direction='column' gap='16px' borderRadius='8px'>
          <Flex gap='16px' align='center'>
            <Text fontSize='14px' fontWeight='600' color='#1D2939'>
              {t('home.cause')}
            </Text>
            <Chip label={t(`home.${AlertCauses[props.alert?.cause]}`)} />
          </Flex>

          <Flex gap='16px' align='center'>
            <Text fontSize='14px' fontWeight='600' color='#1D2939'>
              {t('home.effect')}
            </Text>
            <Chip label={t(`home.${AlertEffects[props.alert?.effect]}`)} />
          </Flex>

          <Text fontSize='14px' color='#1D2939'>
            {props.alert?.description}
          </Text>

          <Flex direction='column' gap='8px' align='flex-start' justify='flex-end'>
            {props.alert?.affectedRoutes && <AffectedRoutesComponent alert={props.alert} network={props.allNetwork} />}
            {props.alert?.affectedStops && <AffectedStopsComponent alert={props.alert} network={props.allNetwork} />}
            {props.alert?.affectedTransports && <AffectedTransportsComponent alert={props.alert} />}
          </Flex>

          <Flex direction='column'>
            <Flex gap='8px' align='center'>
              <Text fontSize='14px' fontWeight='600' color='1D2939'>
                {t('home.effective_from')}
              </Text>
              <Text fontSize='13px' color='#1D2939'>
                {new Date(props.alert?.startDate).toLocaleString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: false,
                })}
              </Text>
            </Flex>
            {props.alert?.endDate && (
              <Flex gap='8px' align='center'>
                <Text fontSize='14px' fontWeight='600' color='1D2939'>
                  {t('network_schedule.to')}
                </Text>
                <Text fontSize='13px' color='#1D2939'>
                  {new Date(props.alert?.endDate).toLocaleString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false,
                  })}
                </Text>
              </Flex>
            )}
          </Flex>

          {props.alert?.url && (
            <Link className='chakra-custom-link' to={props.alert?.url} target={'_blank'}>
              <Flex justify='flex-end' align='flex-end'>
                <Text fontSize='14px' fontWeight='600' color='#1D2939' textDecoration='underline'>
                  {t('ticket_fares.more_info')}
                </Text>
              </Flex>
            </Link>
          )}
        </Flex>
      </ModalContent>
    </Modal>
  )
}
